import React from 'react';

const IconRuby = ({ ...styles }) => (
  <svg viewBox="0 0 300 240" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" {...styles}>
    <defs>
      <linearGradient x1="84.75%" y1="111.399%" x2="58.254%" y2="64.584%" id="_a">
        <stop stopColor="#FB7655" offset="0%" />
        <stop stopColor="#FB7655" offset="0%" />
        <stop stopColor="#E42B1E" offset="41%" />
        <stop stopColor="#900" offset="99%" />
        <stop stopColor="#900" offset="100%" />
      </linearGradient>
      <linearGradient x1="116.651%" y1="60.89%" x2="1.746%" y2="19.288%" id="_b">
        <stop stopColor="#871101" offset="0%" />
        <stop stopColor="#871101" offset="0%" />
        <stop stopColor="#911209" offset="99%" />
        <stop stopColor="#911209" offset="100%" />
      </linearGradient>
      <linearGradient x1="75.774%" y1="219.327%" x2="38.978%" y2="7.829%" id="_c">
        <stop stopColor="#871101" offset="0%" />
        <stop stopColor="#871101" offset="0%" />
        <stop stopColor="#911209" offset="99%" />
        <stop stopColor="#911209" offset="100%" />
      </linearGradient>
      <linearGradient x1="50.012%" y1="7.234%" x2="66.483%" y2="79.135%" id="_d">
        <stop stopColor="#FFF" offset="0%" />
        <stop stopColor="#FFF" offset="0%" />
        <stop stopColor="#E57252" offset="23%" />
        <stop stopColor="#DE3B20" offset="46%" />
        <stop stopColor="#A60003" offset="99%" />
        <stop stopColor="#A60003" offset="100%" />
      </linearGradient>
      <linearGradient x1="46.174%" y1="16.348%" x2="49.932%" y2="83.047%" id="e">
        <stop stopColor="#FFF" offset="0%" />
        <stop stopColor="#FFF" offset="0%" />
        <stop stopColor="#E4714E" offset="23%" />
        <stop stopColor="#BE1A0D" offset="56%" />
        <stop stopColor="#A80D00" offset="99%" />
        <stop stopColor="#A80D00" offset="100%" />
      </linearGradient>
      <linearGradient x1="36.965%" y1="15.594%" x2="49.528%" y2="92.478%" id="f">
        <stop stopColor="#FFF" offset="0%" />
        <stop stopColor="#FFF" offset="0%" />
        <stop stopColor="#E46342" offset="18%" />
        <stop stopColor="#C82410" offset="40%" />
        <stop stopColor="#A80D00" offset="99%" />
        <stop stopColor="#A80D00" offset="100%" />
      </linearGradient>
      <linearGradient x1="13.609%" y1="58.346%" x2="85.764%" y2="-46.717%" id="g">
        <stop stopColor="#FFF" offset="0%" />
        <stop stopColor="#FFF" offset="0%" />
        <stop stopColor="#C81F11" offset="54%" />
        <stop stopColor="#BF0905" offset="99%" />
        <stop stopColor="#BF0905" offset="100%" />
      </linearGradient>
      <linearGradient x1="27.624%" y1="21.135%" x2="50.745%" y2="79.056%" id="h">
        <stop stopColor="#FFF" offset="0%" />
        <stop stopColor="#FFF" offset="0%" />
        <stop stopColor="#DE4024" offset="31%" />
        <stop stopColor="#BF190B" offset="99%" />
        <stop stopColor="#BF190B" offset="100%" />
      </linearGradient>
      <linearGradient x1="-20.667%" y1="122.282%" x2="104.242%" y2="-6.342%" id="i">
        <stop stopColor="#BD0012" offset="0%" />
        <stop stopColor="#BD0012" offset="0%" />
        <stop stopColor="#FFF" offset="7%" />
        <stop stopColor="#FFF" offset="17%" />
        <stop stopColor="#C82F1C" offset="27%" />
        <stop stopColor="#820C01" offset="33%" />
        <stop stopColor="#A31601" offset="46%" />
        <stop stopColor="#B31301" offset="72%" />
        <stop stopColor="#E82609" offset="99%" />
        <stop stopColor="#E82609" offset="100%" />
      </linearGradient>
      <linearGradient x1="58.792%" y1="65.205%" x2="11.964%" y2="50.128%" id="j">
        <stop stopColor="#8C0C01" offset="0%" />
        <stop stopColor="#8C0C01" offset="0%" />
        <stop stopColor="#990C00" offset="54%" />
        <stop stopColor="#A80D0E" offset="99%" />
        <stop stopColor="#A80D0E" offset="100%" />
      </linearGradient>
      <linearGradient x1="79.319%" y1="62.754%" x2="23.088%" y2="17.888%" id="k">
        <stop stopColor="#7E110B" offset="0%" />
        <stop stopColor="#7E110B" offset="0%" />
        <stop stopColor="#9E0C00" offset="99%" />
        <stop stopColor="#9E0C00" offset="100%" />
      </linearGradient>
      <linearGradient x1="92.88%" y1="74.122%" x2="59.841%" y2="39.704%" id="l">
        <stop stopColor="#79130D" offset="0%" />
        <stop stopColor="#79130D" offset="0%" />
        <stop stopColor="#9E120B" offset="99%" />
        <stop stopColor="#9E120B" offset="100%" />
      </linearGradient>
      <linearGradient x1="56.57%" y1="101.717%" x2="3.105%" y2="11.993%" id="o">
        <stop stopColor="#8B2114" offset="0%" />
        <stop stopColor="#8B2114" offset="0%" />
        <stop stopColor="#9E100A" offset="43%" />
        <stop stopColor="#B3100C" offset="99%" />
        <stop stopColor="#B3100C" offset="100%" />
      </linearGradient>
      <linearGradient x1="30.87%" y1="35.599%" x2="92.471%" y2="100.694%" id="p">
        <stop stopColor="#B31000" offset="0%" />
        <stop stopColor="#B31000" offset="0%" />
        <stop stopColor="#910F08" offset="44%" />
        <stop stopColor="#791C12" offset="99%" />
        <stop stopColor="#791C12" offset="100%" />
      </linearGradient>
      <radialGradient cx="32.001%" cy="40.21%" fx="32.001%" fy="40.21%" r="69.573%" id="m">
        <stop stopColor="#A80D00" offset="0%" />
        <stop stopColor="#A80D00" offset="0%" />
        <stop stopColor="#7E0E08" offset="99%" />
        <stop stopColor="#7E0E08" offset="100%" />
      </radialGradient>
      <radialGradient cx="13.549%" cy="40.86%" fx="13.549%" fy="40.86%" r="88.386%" id="n">
        <stop stopColor="#A30C00" offset="0%" />
        <stop stopColor="#A30C00" offset="0%" />
        <stop stopColor="#800E08" offset="99%" />
        <stop stopColor="#800E08" offset="100%" />
      </radialGradient>
    </defs>
    <path d="M197.467 167.764l-145.52 86.41 188.422-12.787L254.88 51.393l-57.414 116.37z" fill="url(#_a)" />
    <path d="M240.677 241.257L224.482 129.48l-44.113 58.25 60.308 53.528z" fill="url(#_b)" />
    <path d="M240.896 241.257l-118.646-9.313-69.674 21.986 188.32-12.673z" fill="url(#_c)" />
    <path d="M52.744 253.955l29.64-97.1L17.16 170.8l35.583 83.154z" fill="url(#_d)" />
    <path d="M180.358 188.05L153.085 81.226l-78.047 73.16 105.32 33.666z" fill="url(#e)" />
    <path d="M248.693 82.73l-73.777-60.256-20.544 66.418 94.321-6.162z" fill="url(#f)" />
    <path d="M214.191.99L170.8 24.97 143.424.669l70.767.322z" fill="url(#g)" />
    <path d="M0 203.372l18.177-33.151-14.704-39.494L0 203.372z" fill="url(#h)" />
    <path
      d="M2.496 129.48l14.794 41.963 64.283-14.422 73.39-68.207 20.712-65.787L143.063 0 87.618 20.75c-17.469 16.248-51.366 48.396-52.588 49-1.21.618-22.384 40.639-32.534 59.73z"
      fill="#FFF"
    />
    <path
      d="M54.442 54.094c37.86-37.538 86.667-59.716 105.397-40.818 18.72 18.898-1.132 64.823-38.992 102.349-37.86 37.525-86.062 60.925-104.78 42.027-18.73-18.885.515-66.032 38.375-103.558z"
      fill="url(#i)"
    />
    <path d="M52.744 253.916l29.408-97.409 97.665 31.376c-35.312 33.113-74.587 61.106-127.073 66.033z" fill="url(#j)" />
    <path d="M155.092 88.622l25.073 99.313c29.498-31.016 55.972-64.36 68.938-105.603l-94.01 6.29z" fill="url(#k)" />
    <path d="M248.847 82.833c10.035-30.282 12.35-73.725-34.966-81.791l-38.825 21.445 73.791 60.346z" fill="url(#l)" />
    <path d="M0 202.935c1.39 49.979 37.448 50.724 52.808 51.162l-35.48-82.86L0 202.935z" fill="#9E1209" />
    <path
      d="M155.232 88.777c22.667 13.932 68.35 41.912 69.276 42.426 1.44.81 19.695-30.784 23.838-48.64l-93.114 6.214z"
      fill="url(#m)"
    />
    <path d="M82.113 156.507l39.313 75.848c23.246-12.607 41.45-27.967 58.121-44.42l-97.434-31.428z" fill="url(#n)" />
    <path
      d="M17.174 171.34l-5.57 66.328c10.51 14.357 24.97 15.605 40.136 14.486-10.973-27.311-32.894-81.92-34.566-80.814z"
      fill="url(#o)"
    />
    <path d="M174.826 22.654l78.1 10.96c-4.169-17.662-16.969-29.06-38.787-32.623l-39.313 21.663z" fill="url(#p)" />
  </svg>
);

export default IconRuby;
