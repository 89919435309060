import React from 'react';

const IconJava = ({ ...styles }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 550" {...styles}>
    <path
      d="M285.104 430.945h-2.038v-1.14h5.486v1.14h-2.024v5.688h-1.424v-5.688zm10.942.297h-.032l-2.019 5.392h-.924l-2.006-5.392h-.025v5.392h-1.342v-6.828h1.975l1.86 4.835 1.854-4.835h1.968v6.828h-1.31v-5.392zM102.681 291.324s-14.178 8.245 10.09 11.035c29.4 3.354 44.426 2.873 76.825-3.259 0 0 8.518 5.341 20.414 9.967-72.63 31.128-164.376-1.803-107.329-17.743M93.806 250.704s-15.902 11.771 8.384 14.283c31.406 3.24 56.208 3.505 99.125-4.759 0 0 5.936 6.018 15.27 9.309-87.814 25.678-185.623 2.025-122.779-18.833"
      fill="#5382a1"
    />
    <path
      d="M168.625 181.799c17.896 20.604-4.702 39.145-4.702 39.145s45.441-23.458 24.572-52.833c-19.491-27.394-34.438-41.005 46.479-87.934 0 0-127.013 31.722-66.349 101.622"
      fill="#f8981d"
    />
    <path
      d="M264.684 321.369s10.492 8.645-11.555 15.333c-41.923 12.7-174.488 16.535-211.314.506-13.238-5.759 11.587-13.751 19.396-15.428 8.144-1.766 12.798-1.437 12.798-1.437-14.722-10.371-95.157 20.364-40.857 29.166 148.084 24.015 269.943-10.814 231.532-28.14M109.499 208.617s-67.431 16.016-23.879 21.832c18.389 2.462 55.047 1.905 89.193-.956 27.906-2.354 55.927-7.359 55.927-7.359s-9.84 4.214-16.959 9.075c-68.475 18.009-200.756 9.631-162.674-8.79 32.206-15.568 58.392-13.802 58.392-13.802M230.462 276.231c69.608-36.171 37.424-70.931 14.96-66.248-5.506 1.146-7.961 2.139-7.961 2.139s2.044-3.202 5.948-4.588c44.441-15.624 78.619 46.081-14.346 70.52 0 .001 1.077-.962 1.399-1.823"
      fill="#5382a1"
    />
    <path
      d="M188.495 4.399s38.55 38.563-36.563 97.862c-60.233 47.568-13.735 74.69-.025 105.678-35.159-31.722-60.961-59.647-43.651-85.637 25.407-38.151 95.793-56.648 80.239-117.903"
      fill="#f8981d"
    />
    <path
      d="M116.339 374.246c66.815 4.277 169.417-2.373 171.847-33.988 0 0-4.671 11.985-55.219 21.503-57.028 10.732-127.364 9.479-169.081 2.601.001-.001 8.54 7.068 52.453 9.884M105.389 495.048c-6.303 5.467-12.96 8.536-18.934 8.536-8.527 0-13.134-5.113-13.134-13.314 0-8.871 4.936-15.357 24.739-15.357h7.328v20.135m17.393 19.623v-60.742c0-15.517-8.85-25.756-30.188-25.756-12.457 0-23.369 3.076-32.238 6.999l2.56 10.752c6.983-2.563 16.022-4.949 24.894-4.949 12.292 0 17.58 4.949 17.58 15.181v7.677h-6.135c-29.865 0-43.337 11.593-43.337 28.994 0 15.017 8.878 23.553 25.594 23.553 10.745 0 18.766-4.436 26.264-10.928l1.361 9.22h13.645zM180.825 514.671h-21.692l-26.106-84.96h18.943l16.199 52.2 3.601 15.699c8.195-22.698 13.991-45.726 16.89-67.899h18.427c-4.937 27.977-13.821 58.685-26.262 84.96M264.038 495.048c-6.315 5.467-12.984 8.536-18.958 8.536-8.512 0-13.131-5.113-13.131-13.314 0-8.871 4.948-15.357 24.749-15.357h7.34v20.135m17.39 19.623v-60.742c0-15.517-8.872-25.756-30.185-25.756-12.466 0-23.382 3.076-32.247 6.999l2.556 10.752c6.986-2.563 16.042-4.949 24.907-4.949 12.283 0 17.579 4.949 17.579 15.181v7.677h-6.145c-29.874 0-43.34 11.593-43.34 28.994 0 15.017 8.871 23.553 25.584 23.553 10.751 0 18.769-4.436 26.28-10.928l1.366 9.22h13.645zM36.847 529.099c-4.958 7.239-12.966 12.966-21.733 16.206L6.527 535.2c6.673-3.424 12.396-8.954 15.055-14.105 2.3-4.581 3.252-10.485 3.252-24.604v-96.995h18.478v95.666c-.001 18.876-1.51 26.501-6.465 33.937"
      fill="#5382a1"
    />
  </svg>
);

export default IconJava;
